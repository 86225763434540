<template>
    <v-container fluid class="my-0 py-0">
        <filters :cup="true" @getRankingByLeague="getRankingByLeague" @getRankingByTeam="getRankingByTeam" @getCupState="getCupState" />
        <v-row>
            <v-col cols="12">
                <v-data-table
                    v-if="matches.length === 0"
                    :headers="headers"
                    :items="ranking"
                    class="elevation-1 rounded-lg py-3 px-3 text-center"
                    hide-default-footer
                    disable-sort
                    :mobile-breakpoint="0"
                    :items-per-page="-1"
                >
                    <template v-slot:item.club="{ item }">
                        <div
                            v-if="item.name === 'Varnava Hawks'"
                            style="position: absolute; text-align: center; top: 5px; font-weight: bolder; font-size: 16px;"
                            class="orange--text"
                        >
                            Αποχώρησαν
                        </div>
                        <v-row align="center" justify="center" :class="item.name === 'Varnava Hawks' ? 'overlay' : ''">
                            <v-col cols="3">
                                <v-img
                                    v-if="item.imageUrl"
                                    :src="`${assetsUrl}/${item.imageUrl}`"
                                    height="40px"
                                    width="40px"
                                    contain
                                />
                                <v-icon v-else :style="$vuetify.breakpoint.mobile ? '' : 'font-size: 40px'" class="orange--text mt-3"> mdi-basketball </v-icon>
                            </v-col>
                            <v-col cols="9">
                                <span class="font-weight-bold">{{ item.name }}</span>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.gp="{ item }">
                        <v-row align="center">
                            <v-col cols="12">
                                {{ (item.won + item.lost) }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.win_percentage="{ item }">
                        <v-row align="center">
                            <v-col cols="12">
                                {{ `${(item.won + item.lost) > 0 ? (item.won / (item.won + item.lost) * 100).toFixed(1) : 0}%` }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.position="{ item }">
                        <v-row align="center">
                            <v-col cols="12">
                                {{ ranking.indexOf(item) + 1 }}
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
                <v-row v-else align="center">
                    <v-col cols="12" style="position: relative;" class="d4" align="center">
                        <table>
                            <tr>
                                <th
                                    v-for="i in cupRound"
                                    :key="i"
                                >
                                    <v-card
                                        color="custom-orange"
                                        class="white--text"
                                    >
                                        {{ getCupRoundName(i) }}
                                    </v-card>
                                </th>
                            </tr>
                            <tr>
                                <td
                                    v-for="i in cupRound"
                                    :key="i"
                                    style="vertical-align: baseline;"
                                >
                                    <graph-match-card
                                        v-for="match in matches.filter((element) => element.round === i)"
                                        :key="match.id"
                                        class="my-5"
                                        :match="match"
                                    />
                                </td>
                            </tr>
                        </table>
                        <!-- <v-col v-for="i in cupRound" cols="3" :key="i">
                            <v-card color="custom-orange" class="white--text"> {{ `Round ${i}` }} </v-card>
                            <graph-match-card class="my-5" v-for="match in matches.filter((element) => element.round === i)" :key="match.id" :match="match"/>
                        </v-col> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Filters from '@/components/GenericFilters'
    import GraphMatchCard from '@/components/GraphMatchCard'
    import teamService from '@/services/team'
    import matchService from '@/services/match'
    import leagueService from '@/services/league.js'

    export default {
        components: { Filters, GraphMatchCard },
        data() {
            return {
                headers: [
                    { text: 'Club', value: 'club', width: '200px', align: 'start' },
                    { text: 'Points', value: 'standing', width: '50px', align: 'center' },
                    { text: 'GP', value: 'gp', width: '50px', align: 'center' },
                    { text: 'Won', value: 'won', width: '50px', align: 'center' },
                    { text: 'Lost', value: 'lost', width: '50px', align: 'center' },
                    { text: 'Win(%)', value: 'win_percentage', width: '50px', align: 'center' },
                    { text: 'Pts+', value: 'pointsPlus', width: '50px', align: 'center' },
                    { text: 'Pts-', value: 'pointsMinus', width: '50px', align: 'center' },
                    { text: '+/-', value: 'pointsDiff', width: '50px', align: 'center' }
                ],
                ranking: [],
                assetsUrl: 'https://storage.googleapis.com',
                type: 'regular_season',
                matches: [],
                tableMatches: {},
                cupRound: 1
            }
        },
        created() {
        },
        methods: {
            getCupRoundName(i) {
                if (i === this.cupRound) {
                    return 'Finals'
                } else if (i === this.cupRound - 1) {
                    return 'Semi-Finals'
                } else if (i === this.cupRound - 2) {
                    return 'Quarter-Finals'
                } else {
                    return `Round ${i}`
                }
            },
            getCupState(isCup, leagueId, seasonId) {
                let filters = {}
                if (isCup) {
                    filters = { cup: `isCup: ${isCup}` }
                } else {
                    filters = { phase: 'phase: playoffs', league: `league: ${leagueId}`, season: `season: ${seasonId}` }
                }
                matchService.getMatches(1, 100, filters).then(response => {
                    this.matches = response.data.content
                    this.setCupRound()
                    this.getCupMatches()
                })
            },
            getCupMatches() {
                for (let index = 1; index <= this.cupRound; index++) {
                    this.tableMatches[index] = []
                    this.matches.filter((element) => element.round === index).forEach(match => {
                        this.tableMatches[index].push(match)
                    })
                }
            },
            setCupRound() {
                this.cupRound = this.matches.reduce((prev, current) => {
                    return (prev && prev.round > current.round) ? prev : current
                }, 0).round
            },
            getRankingByTeam(teamId, type) {
                this.matches = []
                teamService.getRankingByTeam(teamId, type).then(resp => {
                    this.ranking = resp.data
                    // this.ranking.sort(this.compare)
                })
            },
            getRankingByLeague(leagueId, seasonId, type) {
                this.matches = []
                leagueService.getRankingByLeague(leagueId, seasonId, type).then(resp => {
                    this.ranking = resp.data
                    // this.ranking.sort(this.compare)
                })
            },
            compare(a, b) {
                if (a.standing < b.standing) {
                    return 1
                }
                if (a.standing > b.standing) {
                    return -1
                }
                if (a.standing === b.standing) {
                    return b.pointsDiff >= a.pointsDiff ? 1 : -1
                }
                return 0
            }
        }
    }
</script>

<style scoped>
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.2;
}

.bg {
    position: absolute;
    background: url('../assets/basketball-bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 100%;
}

.d4 {
    position: relative;
    max-height: 100%;
    overflow: auto;
}
</style>
